// src/app/chat-model/chat-model.component.ts
import { Component, ViewChild, ElementRef, Inject, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject } from 'rxjs';

interface ChatMessage {
    text: string;
    addedBy: "USER" | "SYSTEM";
    stepStatus?: string;
    stepId?: string;
    isFailedStep?: boolean;
    newLocator?: string;
    newMetadata?: string;
}

@Component({
    selector: 'app-chat-model',
    templateUrl: './chat-model.component.html',
    styleUrls: ['./chat-model.component.scss'],
})
export class ChatModelComponent {
    @ViewChild('chatContainer') private chatContainer: ElementRef;
    @ViewChild('dialogContainer') dialogContainer!: ElementRef;

    public Prop: any;
    public Conversation: any;
    public chatLogs: ChatMessage[] = [];
    public message: string = '';
    public newMetadata: string = '';
    public locatorNotDetect: boolean = false;
    public remainingTime$ = new BehaviorSubject<number>(0);
    public isTipsOpen: boolean = false;
    public stepData: any;
    activeInput: 'metadata' | 'chat' | null = null;
    private isDragging = false;
    private currentX = 0;
    private currentY = 0;
    private initialX = 0;
    private initialY = 0;
    private xOffset = 0;
    private yOffset = 0;

    readonly CIRCLE_CIRCUMFERENCE = 2 * Math.PI * 52;

    slides = [
        {
            icon: 'smart_toy',
            iconClass: 'ai-assistant',
            title: 'AI Assistant',
            description: 'Get help with test automation, debugging, and optimization suggestions.'
        },
        {
            icon: 'bug_report',
            iconClass: 'debug',
            title: 'Debug Assistant',
            description: 'Analyze and fix test failures with AI-powered debugging assistance.'
        },
        {
            icon: 'auto_fix_high',
            iconClass: 'optimize',
            title: 'Test Optimization',
            description: 'Receive suggestions to improve test reliability and performance.'
        }
    ];

    constructor(
        private dialogRef: MatDialogRef<ChatModelComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private socket: Socket,
        public changeDetectorRef: ChangeDetectorRef
    ) {
        this.initializeData();

        this.dialogRef.componentInstance?.data.subscribe((newData: any) => {
            if (newData?.stepData && this.stepData?.id !== newData.stepData.id) {
                this.updateStepDataFromParent(newData);
            }
        });
    }

    private initializeData() {
        if (this.data) {
            this.Prop = this.data.Prop || {};
            this.Conversation = this.data.Conversation || {};
            this.chatLogs = this.data.chatLogs || [];
            this.stepData = this.data.stepData || {};

            this.locatorNotDetect = this.Conversation?.stepStatus === 'LOCATOR_NOT_DETECT';
            this.newMetadata = null;

            if (this.Conversation?.stepStatus === 'TIMEOUT') {
                this.remainingTime$.next(50);
            } else if (this.locatorNotDetect) {
                this.remainingTime$.next(120);
            }
        }
    }

    private updateStepDataFromParent(newData: any) {
        this.stepData = newData.stepData || {};

        if (newData.Conversation?.stepStatus) {
            this.Conversation = newData.Conversation;
            this.locatorNotDetect = this.Conversation.stepStatus === 'LOCATOR_NOT_DETECT';

            if (this.Conversation.stepStatus === 'TIMEOUT') {
                this.remainingTime$.next(50);
            } else if (this.locatorNotDetect) {
                this.remainingTime$.next(120);
            }
        }

        this.changeDetectorRef.detectChanges();
    }

    public updateData(newData: any) {
        this.updateStepDataFromParent(newData);
    }


    ngAfterViewInit() {
        this.scrollChatToBottom();
    }

    closeDialog() {
        this.dialogRef.close();
    }

    updateStepData(action: string, type: 'locator' | 'metadata') {
        this.socket.emit('internal', {
            action: action,
            type: type,
            value: this.newMetadata,
            topic: this.Conversation?.replyBack_id,
            stepId: this.stepData.id,
        });
        this.newMetadata = '';
        // this.closeDialog();
    }

    handleStepAction(action: string) {
        this.socket.emit('internal', {
            action: action,
            topic: this.Conversation?.replyBack_id,
            stepId: this.stepData.id,
        });
        // this.closeDialog();
    }

    sendevent() {
        if (!this.message.trim()) return;

        if (this.Conversation?.stepStatus === 'LOCATOR_NOT_DETECT') {
            this.chatLogs.push({ text: 'Locator updated', addedBy: 'USER' });
            this.socket.emit('internal', {
                message: 'Locator updated',
                topic: this.Conversation?.replyBack_id,
            });
        } else {
            this.chatLogs.push({ text: this.message, addedBy: 'USER' });
            this.socket.emit('internal', {
                message: this.message,
                topic: this.Conversation?.replyBack_id,
            });
        }

        this.message = '';
        this.scrollChatToBottom();
        this.changeDetectorRef.detectChanges();
    }

    formatTime(seconds: number): string {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    }

    getDashOffset(remainingTime: number): number {
        const progress = 1 - remainingTime / (2 * 60);
        return this.CIRCLE_CIRCUMFERENCE * progress;
    }

    private scrollChatToBottom(): void {
        try {
            if (this.chatContainer) {
                this.chatContainer.nativeElement.scrollTop =
                    this.chatContainer.nativeElement.scrollHeight;
            }
        } catch (err) {
            console.error('Error scrolling chat:', err);
        }
    }

    setActiveInput(type: 'metadata' | 'chat') {
        this.activeInput = type;
        if (type === 'metadata') {
            // this.Prop.stepFrom = 'AI_AGENT';
            // this.activeInput = 'chat'
            this.message = '';
        } else {
            this.newMetadata = '';
        }
    }

    onMouseDown(event: MouseEvent) {
        if (event.target instanceof Element &&
            (event.target.classList.contains('header') ||
                event.target.closest('.header'))) {
            this.initialX = event.clientX - this.xOffset;
            this.initialY = event.clientY - this.yOffset;
            this.isDragging = true;
        }
    }

    onMouseMove(event: MouseEvent) {
        if (this.isDragging) {
            event.preventDefault();
            this.currentX = event.clientX - this.initialX;
            this.currentY = event.clientY - this.initialY;
            this.xOffset = this.currentX;
            this.yOffset = this.currentY;

            // Get the dialog element using closest() to find the mat-dialog-container
            const dialogElement = event.target instanceof Element ?
                event.target.closest('.mat-dialog-container') as HTMLElement : null;

            if (dialogElement) {
                dialogElement.style.transform = `translate3d(${this.currentX}px, ${this.currentY}px, 0)`;
            }
        }
    }

    onMouseUp() {
        this.isDragging = false;
    }
}
