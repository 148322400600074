<div
  class="details-page-container"
  style="height: 75px !important; background-color: white"
>
  <app-placeholder-loader
    class="ts-col-100"
    *ngIf="!isTestCaseFetchingCompleted"
    [isDetails]="true"
  ></app-placeholder-loader>
  <div class="ts-col-100 d-flex-wrap" *ngIf="testCase">
    <div class="details-page-header">
      <app-td-overlay-menu-button
        [versionId]="testCase?.workspaceVersionId"
      ></app-td-overlay-menu-button>
      <!-- <a class="go-back-icon pl-10"
         [matTooltip]="'hint.message.common.back' | translate"
         [routerLink]="['/td', testCase?.workspaceVersionId, testCase?.testcaseRedirection]"></a> -->
      <div
        class="d-flex"
        style="
          justify-content: space-between;
          width: 100%;
          position: relative;
          top: -3px;
        "
      >
        <div class="theme-details-header ml-15">
          <a
            class="title-name"
            [matTooltip]="testCase.name"
            [textContent]="testCase.name"
            style="color: black !important; font-size: 22px !important"
          ></a>

          <span
            [matMenuTriggerFor]="moreOptions"
            style="
              border: 1px solid #acaee5; /* Solid border with 10px thickness and gray color */
              border-radius: 50%;
              margin-left: 14px;
              text-align: center;
              cursor: pointer;
              width: 30px !important;
              height: 30px !important;
              align-items: center;
              display: flex;
              justify-content: center;
            "
            ><mat-icon style="color: #6366f1"> more_horiz </mat-icon></span
          >
        </div>
        <!-- <div style="margin-left: 500px;">
            <button class="theme-btn-primary"
            (click)="testPopup()"
            >testpop</button>
        </div> -->

        <mat-menu #moreOptions="matMenu">
          <button
            mat-menu-item
            *ngIf="!testCase?.deleted"
            (click)="openTestCaseClone()"
            style="color: #6366f1"
            [matTooltip]="'hint.message.common.clone' | translate"
          >
            <mat-icon
              style="
                color: #6366f1;
                font-size: 12px;
                padding: 4px;
                border: 1px solid #acaee5;
                border-radius: 19px; /* margin-left: 5px; */
                text-align: center;
                cursor: pointer;
                position: relative; /* top: 0px; */
              "
              >filter_none</mat-icon
            >Clone
          </button>
          <button
            mat-menu-item
            *ngIf="!testCase?.deleted"
            style="color: #6366f1"
            [routerLink]="[
              '/td',
              testCase.workspaceVersionId,
              testCase.isStepGroup ? 'step_groups' : 'cases',
              testCaseId,
              'edit'
            ]"
            [matTooltip]="'hint.message.common.edit' | translate"
          >
            <mat-icon
              style="
                color: #6366f1;
                font-size: 12px;
                padding: 4px;
                border: 1px solid #acaee5;
                border-radius: 19px; /* margin-left: 5px; */
                text-align: center;
                cursor: pointer;
                position: relative; /* top: 0px; */
              "
              >border_color</mat-icon
            >Edit
          </button>
          <button
            mat-menu-item
            *ngIf="!testCase?.deleted"
            (click)="deleteTestCase()"
            style="color: #6366f1"
            [matTooltip]="'hint.message.common.delete' | translate"
          >
            <mat-icon
              style="
                color: #6366f1;
                font-size: 12px;
                padding: 4px;
                border: 1px solid #acaee5;
                border-radius: 19px; /* margin-left: 5px; */
                text-align: center;
                cursor: pointer;
                position: relative; /* top: 0px; */
              "
              >delete</mat-icon
            >Delete
          </button>
          <button
            mat-menu-item
            (click)="openDetails()"
            style="color: #6366f1"
            [matTooltip]="'hint.message.common.more' | translate"
          >
            <mat-icon
              style="
                color: #6366f1;
                font-size: 12px;
                padding: 4px;
                border: 1px solid #acaee5;
                border-radius: 19px; /* margin-left: 5px; */
                text-align: center;
                cursor: pointer;
                position: relative; /* top: 0px; */
              "
              >more_horiz</mat-icon
            >More
          </button>
          <!-- <button mat-menu-item (click)="reportBug()" class="icon-btn border-rds-2 ml-14"><img src="/assets/images/jira-software-blue.svg" width="40px"/></button> -->
          <button
            mat-menu-item
            (click)="reportBug()"
            style="color: #6366f1"
            [matTooltip]="'hint.message.common.jira_bug_report' | translate"
          >
            <mat-icon
              style="
                color: #6366f1;
                font-size: 12px;
                padding: 4px;
                border: 1px solid #acaee5;
                border-radius: 19px; /* margin-left: 5px; */
                text-align: center;
                cursor: pointer;
                position: relative; /* top: 0px; */
              "
              >bug_report</mat-icon
            >Jira
          </button>
          <div
            [matTooltip]="'Make it test case successful run'"
            [matTooltipDisabled]="
              dryTestCaseResults?.totalElements &&
              dryTestCaseResults.totalElements > 0
            "
          >
            <button
              mat-menu-item
              (click)="generateMore()"
              style="color: #6366f1"
              [disabled]="
                !dryTestCaseResults?.totalElements ||
                dryTestCaseResults.totalElements === 0
              "
              [ngStyle]="{
                'background-color':
                  !dryTestCaseResults?.totalElements ||
                  dryTestCaseResults.totalElements === 0
                    ? '#d3d3d3'
                    : 'white',
                color:
                  !dryTestCaseResults?.totalElements ||
                  dryTestCaseResults.totalElements === 0
                    ? '#a0a0a0'
                    : '#6366f1',
                cursor:
                  !dryTestCaseResults?.totalElements ||
                  dryTestCaseResults.totalElements === 0
                    ? 'not-allowed'
                    : 'pointer'
              }"
            >
              <mat-icon
                [ngStyle]="{
                  color:
                    !dryTestCaseResults?.totalElements ||
                    dryTestCaseResults.totalElements === 0
                      ? '#a0a0a0'
                      : '#6366f1',
                  border:
                    !dryTestCaseResults?.totalElements ||
                    dryTestCaseResults.totalElements === 0
                      ? '1px solid #a0a0a0'
                      : '1px solid #6366f1'
                }"
                style="
                  color: #6366f1;
                  font-size: 12px;
                  padding: 4px;
                  border: 1px solid #acaee5;
                  border-radius: 19px;
                  text-align: center;
                  cursor: pointer;
                  position: relative;
                  margin-right: 12px;
                "
                >developer_board</mat-icon
              >
              Generate More
            </button>
          </div>
        </mat-menu>

        <div class="details-page-buttons">
          <div
            style="margin-right: 5px"
            class="position-relative d-flex align-items-center"
          >
            <button
              class="theme-btn-primary ml-5"
              (click)="Validate()"
              style="height: 36px; min-width: 100px"
              *ngIf="!sharedDataService.isRunningValidate"
            >
              Validate
            </button>
            <span class="ml-5" *ngIf="!lockData?.Debug">
              <app-common-button
                label="Debug"
                style="height: 36px; min-width: 100px"
              ></app-common-button>
            </span>
            <span class="ml-5" *ngIf="lockData?.Debug">
              <button
                class="theme-btn-primary"
                (click)="onDebugge()"
                style="height: 36px; min-width: 100px"
              >
                <i class="fa-solid fa-bug pr-6"></i>
                Debug
              </button>
            </span>

            <picture *ngIf="sharedDataService.isRunningValidate">
              <img
                src="/assets/process.gif"
                width="40px"
                alt=""
                style="position: absolute; right: 100px; top: -5px"
              />
            </picture>
          </div>
          <div *ngIf="version?.workspace?.isMobileNative && !testCase?.deleted">
            <button
              *ngIf="hasInspectorFeature()"
              [routerLink]="['/td', 'record', testCase.workspaceVersionId]"
              [queryParams]="{
                isRecord: true,
                isStepRecord: true,
                testCaseId: testCaseId
              }"
              class="theme-btn-clear-default mr-10"
              [translate]="'elements.btn.record'"
            ></button>
          </div>
          <button
            *ngIf="!checkExtensionInstall"
            (click)="openSidePanelAndListSteps()"
            class="theme-btn-primary nlp-record-default-button mr-10 position-relative py-6 text-nowrap ml-5"
          >
            <div
              class="rounded-circle btn mr-5 p-4 my-2 result-status-0"
              style="background: white"
            ></div>
            <span>Record</span>
          </button>

          <!-- install_plugin -->
          <button
            *ngIf="checkExtensionInstall"
            class="theme-btn-primary"
            style="height: 36px; min-width: 100px"
            (click)="installPlugin()"
          >
            <img
              style="height: 13px"
              src="../../assets/color_icons/cqa-logo.svg"
            />
            &nbsp; <span [translate]="'Install Plugin'"></span>
          </button>
          <!-- <div class="mr-10">
            <button class="theme-btn-primary" *ngIf="cqaOrigin != 'dtest01'" (click)="manuallyRun()" style="height: 36px;">Run
              Manually</button>
          </div> -->
          <div>
            <button
              *ngIf="
                stepsLength &&
                canShowRunResult &&
                !canShowBulkActions &&
                cqaOrigin !== 'dtest01' &&
                cqaOrigin !== 'coforgesita' &&
                cqaOrigin !== 'p1818' &&
                cqaOrigin !== 'honeylove'
              "
              style="height: 36px"
              class="theme-btn-primary ml-5"
              [disabled]="IsExecuteBtn"
              (click)="OnRun()"
            >
              <i class="fa-filled-circled-play pr-7"></i>
              <span
                [translate]="
                  IsExecuteBtn ? 'test_plan.btn.executing' : 'test_plan.btn.run'
                "
              ></span>
            </button>

            <button
              *ngIf="
                stepsLength &&
                canShowRunResult &&
                !canShowBulkActions &&
                (cqaOrigin === 'dtest01' ||
                  cqaOrigin === 'p1818' ||
                  cqaOrigin === 'coforgesita' ||
                  cqaOrigin === 'honeylove')
              "
              [matMenuTriggerFor]="runOptions"
              class="theme-btn-primary ml-5"
              [disabled]="IsExecuteBtn"
              style="padding: 9px"
            >
              <i class="fa-filled-circled-play pr-7"></i>
              <span
                [translate]="
                  IsExecuteBtn ? 'test_plan.btn.executing' : 'test_plan.btn.run'
                "
              ></span>
            </button>

            <mat-menu #runOptions="matMenu">
              <button
                mat-menu-item
                (click)="OnRun()"
                style="color: #6366f1"
                *ngIf="
                  cqaOrigin === 'coforgesita' ||
                  cqaOrigin === 'honeylove' ||
                  cqaOrigin === 'dtest01' ||
                  cqaOrigin === 'p1818'
                "
              >
                <mat-icon
                  style="
                    color: #6366f1;
                    font-size: 12px;
                    padding: 4px;
                    border: 1px solid #acaee5;
                    border-radius: 19px; /* margin-left: 5px; */
                    text-align: center;
                    cursor: pointer;
                    position: relative; /* top: 0px; */
                  "
                >
                  running_with_errors
                </mat-icon>
                Execution
              </button>
              <button
                mat-menu-item
                (click)="manuallyRun()"
                style="color: #6366f1"
                *ngIf="cqaOrigin !== 'coforgesita' && cqaOrigin !== 'honeylove'"
              >
                <mat-icon
                  style="
                    color: #6366f1;
                    font-size: 12px;
                    padding: 4px;
                    border: 1px solid #acaee5;
                    border-radius: 19px; /* margin-left: 5px; */
                    text-align: center;
                    cursor: pointer;
                    position: relative; /* top: 0px; */
                  "
                >
                  assignment
                </mat-icon>
                Run Manually
              </button>
              <button
                mat-menu-item
                (click)="reportsExecution('performance')"
                style="color: #6366f1"
                *ngIf="
                  cqaOrigin === 'coforgesita' ||
                  cqaOrigin === 'honeylove' ||
                  cqaOrigin === 'p1818'
                "
              >
                <mat-icon
                  style="
                    color: #6366f1;
                    font-size: 12px;
                    padding: 4px;
                    border: 1px solid #acaee5;
                    border-radius: 19px; /* margin-left: 5px; */
                    text-align: center;
                    cursor: pointer;
                    position: relative; /* top: 0px; */
                  "
                >
                  speed
                </mat-icon>
                Performance Testing
              </button>
              <button
                mat-menu-item
                (click)="reportsExecution('security')"
                style="color: #6366f1"
                *ngIf="cqaOrigin !== 'coforgesita' && cqaOrigin !== 'honeylove'"
              >
                <mat-icon
                  style="
                    color: #6366f1;
                    font-size: 12px;
                    padding: 4px;
                    border: 1px solid #acaee5;
                    border-radius: 19px; /* margin-left: 5px; */
                    text-align: center;
                    cursor: pointer;
                    position: relative; /* top: 0px; */
                  "
                >
                  verified_user
                </mat-icon>
                Security Testing
              </button>
              <button
                mat-menu-item
                (click)="reportsExecution('load')"
                style="color: #6366f1"
                *ngIf="cqaOrigin !== 'coforgesita' && cqaOrigin !== 'honeylove'"
              >
                <mat-icon
                  style="
                    color: #6366f1;
                    font-size: 12px;
                    padding: 4px;
                    border: 1px solid #acaee5;
                    border-radius: 19px; /* margin-left: 5px; */
                    text-align: center;
                    cursor: pointer;
                    position: relative; /* top: 0px; */
                  "
                >
                  cloud
                </mat-icon>
                Load Testing
              </button>
              <button
                mat-menu-item
                (click)="OnSecurityAgent()"
                style="color: #6366f1"
                *ngIf="cqaOrigin === 'dtest01'"
              >
                <mat-icon
                  style="
                    color: #6366f1;
                    font-size: 12px;
                    padding: 4px;
                    border: 1px solid #acaee5;
                    border-radius: 19px; /* margin-left: 5px; */
                    text-align: center;
                    cursor: pointer;
                    position: relative; /* top: 0px; */
                  "
                >
                  security
                </mat-icon>
                Security Agent
              </button>
              <button
                mat-menu-item
                (click)="reportsExecution('accessibility')"
                style="color: #6366f1"
                *ngIf="cqaOrigin === 'coforgesita' || cqaOrigin === 'honeylove'"
              >
                <mat-icon
                  style="
                    color: #6366f1;
                    font-size: 12px;
                    padding: 4px;
                    border: 1px solid #acaee5;
                    border-radius: 19px; /* margin-left: 5px; */
                    text-align: center;
                    cursor: pointer;
                    position: relative; /* top: 0px; */
                  "
                >
                  accessibility
                </mat-icon>
                Accessibility Testing
              </button>
            </mat-menu>
          </div>

          <app-test-case-detail-pagination
            *ngIf="!testCase?.deleted && false"
            [versionId]="testCase?.workspaceVersionId"
            [testCase]="testCase"
          ></app-test-case-detail-pagination>
          <!-- <div *ngIf="version?.workspace?.isWeb"> -->
          <!-- <app-chrome-record-button *ngIf="isSidePanelOPened" (messageEvent)="receiveMessage($event)"></app-chrome-record-button> -->

          <!-- </div> -->
          <button
            *ngIf="testCase?.deleted"
            (click)="restore()"
            class="btn icon-btn border-rds-2 ml-14 text-success"
          >
            <i class="fa-rev-brands mr-10"></i>
            <span [translate]="'btn.common.restore'"></span>
          </button>
          <button
            *ngIf="testCase?.deleted"
            (click)="fetchLinkedCases()"
            [disabled]="isDeleting"
            class="btn icon-btn border-rds-2 ml-14 text-danger"
          >
            <i class="fa-trash mr-10"></i>
            <span [translate]="'btn.common.delete_permanently'"></span>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="testCase?.isStepGroup">
      <div class="py-5 pl-10 pr-10 theme-yellow w-fit-content border-rds-3">
        <i class="fa-warning text-danger"></i>
        <span
          [translate]="'testcase.details.step_group.edit_step.warning'"
          class="pl-10 fsm"
        ></span>
      </div>
    </div>
  </div>
</div>
<div class="theme-details-scroll sm-h">
  <router-outlet></router-outlet>
</div>
<div
  class="loading-overlay-custom"
  *ngIf="loadingSpinner"
  style="
    position: fixed;
    top: 0;
    left: 2;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 18%); /* White blur effect */
    backdrop-filter: blur(2px); /* Apply blur to background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it stays on top */
  "
>
  <mat-progress-spinner
    style="position: relative; top: 50%; right: 50%"
    class="search-spinner"
    mode="indeterminate"
    diameter="40"
  ></mat-progress-spinner>
</div>
