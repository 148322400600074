import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { ExecutionModelComponent } from '../results/execution/execution-model/execution-model.component';
import { KnowledgeBaseService } from '../../services/knowledge-base.service';
import { TestStepService } from '../../services/test-step.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';
import { MixpanelService } from '../../mixpanel.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-test-case-details-knowledge-base',
  template: `
    <div class="dialog-header">
      <h2>Execute Test Case</h2>
      <button mat-icon-button (click)="onCancel()">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <form [formGroup]="executionForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label>Knowledge Base</label>
        <mat-form-field appearance="outline" class="w-100">
          <mat-select formControlName="knowledgeBase">
            <mat-option [value]="null">None</mat-option>
            <mat-option *ngFor="let kb of knowledgeBaseList" [value]="kb.id">
              {{kb.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-group">
        <label>Persona</label>
        <mat-form-field appearance="outline" class="w-100">
          <mat-select formControlName="persona">
            <mat-option [value]="null">None</mat-option>
            <mat-option *ngFor="let persona of personaList" [value]="persona.id">
              {{persona.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="actions">
        <button mat-button [disabled]="IsExecuteBtn" (click)="onCancel()">Cancel</button>
        <button 
          mat-flat-button 
          color="primary" 
          type="submit"
          [disabled]="IsExecuteBtn">
          <mat-spinner 
            *ngIf="IsExecuteBtn" 
            diameter="20" 
            class="inline-spinner">
          </mat-spinner>
          <span *ngIf="!IsExecuteBtn">Start Execution</span>
          <span *ngIf="IsExecuteBtn">Starting...</span>
        </button>
      </div>
    </form>
  `,
  styles: [`
    .dialog-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
    }
    .form-group {
      padding: 16px;
    }
    .actions {
      padding: 16px;
      display: flex;
      justify-content: flex-end;
      gap: 8px;
    }
    .inline-spinner {
      display: inline-block;
      margin-right: 8px;
    }
    button[disabled] {
      opacity: 0.7;
    }
  `]
})
export class TestCaseDetailsKnowledgeBaseComponent {
  executionForm: FormGroup;
  knowledgeBaseList = [];
  personaList = [];
  isLoading = false;
  IsExecuteBtn = false;

  constructor(
    private dialogRef: MatDialogRef<TestCaseDetailsKnowledgeBaseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private knowledgeBaseService: KnowledgeBaseService,
    private testStepService: TestStepService,
    private translate: TranslateService,
    private notificationsService: NotificationsService,
    private mixpanelService: MixpanelService
  ) {
    this.executionForm = this.fb.group({
      knowledgeBase: [null],
      persona: [null]
    });
    this.loadKnowledgeBases();
    this.loadPersonas();
  }

  private loadKnowledgeBases() {
    this.isLoading = true;
    this.knowledgeBaseService.getKnowledgeBases().subscribe({
      next: (response) => {
        this.knowledgeBaseList = response?.response?.prompts?.map(kb => ({
          id: kb.id,
          name: kb.title || ''
        })) || [];
        this.isLoading = false;
      },
      error: () => {
        this.knowledgeBaseList = [];
        this.isLoading = false;
      }
    });
  }

  private loadPersonas() {
    this.isLoading = true;
    this.knowledgeBaseService.getPersonas().subscribe({
      next: (response: any) => {
        console.log("response ::", response)
        this.personaList = response?.response?.map(p => ({
          id: p.id,
          name: p.name || ''
        })) || [];
        this.isLoading = false;
      },
      error: () => {
        this.personaList = [];
        this.isLoading = false;
      }
    });
  }

  onSubmit() {
    if (!this.IsExecuteBtn) {
      this.IsExecuteBtn = true;
      const formValue = this.executionForm.value;
      
      // Build parameter string with both knowledgeBase and persona
      let parameters = [];
      if (formValue.knowledgeBase) {
        parameters.push(`knowledgeId=${formValue.knowledgeBase}`);
      }
      if (formValue.persona) {
        parameters.push(`personaId=${formValue.persona}`);
        // parameters.push(`agentId=${formValue.knowledgeBase}`);
      }
      const parameter = parameters.join('&');
      
      this.testStepService.showexecute(this.data.testCaseId, parameter).subscribe({
        next: (step) => {
          this.mixpanelService.track('Test Case Execution Started', {
            "id": this.data.testCase.id,
            "title": this.data.testCase.name
          });
          this.IsExecuteBtn = false;
          
          this.translate.get("execution.result.SUCCESS").subscribe(() => {
            this.showNotification(NotificationType.Success, "Test case execution initiated successfully");
            this.dialogRef.close({
              success: true,
              topicId: step?.topic,
              testRunId: step?.data[0].id,
              formData: formValue
            });
          });
        },
        error: (error: HttpErrorResponse) => {
          this.IsExecuteBtn = false;
          if (error.status == 400 && error.error?.error && typeof error.error.error == "string") {
            this.showAPIError(error, this.translate.instant(error.error.error || "execution.initiate.failure"));
          } else {
            this.showAPIError(error, this.translate.instant("execution.initiate.failure"));
          }
          this.mixpanelService.track('Test Case Execution Failed', {
            "id": this.data.testCase.id,
            "title": this.data.testCase.name
          });
          this.dialogRef.close({ success: false });
        }
      });
    }
  }

  private showNotification(type: NotificationType, message: string) {
    this.notificationsService.create('', message, type, {
      timeOut: 2000,  // Will disappear after 3 seconds
      showProgressBar: true,
      pauseOnHover: true,
      clickToClose: true
    });
  }

  private showAPIError(error: HttpErrorResponse, defaultMessage: string) {
    this.showNotification(NotificationType.Error, error.error?.message || defaultMessage);
  }

  onCancel() {
    this.dialogRef.close();
  }
}