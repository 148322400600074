<div class="chat-container" 
     (mousedown)="onMouseDown($event)"
     (mousemove)="onMouseMove($event)"
     (mouseup)="onMouseUp()"
     (mouseleave)="onMouseUp()">
  <div class="header" style="cursor: move;">
    <div class="version-selector">
      <span class="version new"
        >AI Test Assistant <mat-icon>smart_toy</mat-icon></span
      >
    </div>
    <div class="action-buttons">
      <button mat-icon-button (click)="closeDialog()" matTooltip="Close">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div class="chat-area">
    <!-- Welcome message -->
    <div class="welcome-message" *ngIf="!chatLogs?.length">
      <p>Welcome to AI Test Assistant</p>
      <p>How can I help you with your test automation today?</p>
    </div>

    <!-- Chat messages -->
    <div class="chat-messages-container" #chatContainer>
      <div
        *ngFor="let message of chatLogs"
        class="message"
        [ngClass]="{
          'ai-message': message.addedBy === 'SYSTEM',
          'user-message': message.addedBy === 'USER',
          'failed-message': message.isFailedStep
        }"
      >
        <div class="message-avatar">
          <mat-icon>{{
            message.addedBy === "SYSTEM" ? "smart_toy" : "person"
          }}</mat-icon>
        </div>
        <div class="message-content">
          <p>{{ message.text }}</p>
        </div>
      </div>
      <div class="action-buttons-container" *ngIf="Prop.stepFrom === 'DEBUGGE_WITH_AI'">
        <button
          mat-button
          matTooltip="Provide More information about your steps in Normal English"
          class="action-button modify"
          (click)="setActiveInput('metadata')"
        >
          <mat-icon>edit</mat-icon>
          Provide additional details
        </button>
        <button
          mat-button
          matTooltip="Click on Skip Step if you want to remove this step only for this execution."
          class="action-button generate-screens"
          (click)="handleStepAction('skip')"
        >
          <mat-icon>skip_next</mat-icon>
          Skip Step
        </button>
        <button
          mat-button
          matTooltip="Click on Skip and Delete If you want to delete step permanently ."
          class="action-button generate-images"
          (click)="handleStepAction('skip_delete')"
        >
          <mat-icon>delete_outline</mat-icon>
          <span>Skip and Delete Step</span>
        </button>
      </div>
    </div>

    <!-- Chat Input -->
    <div class="chat-footer" >
      <div class="chat-input-section" *ngIf="Prop.stepFrom === 'AI_AGENT' || activeInput === 'chat'">
        <div class="input-area">
          <textarea
            class="custom-input"
            [(ngModel)]="message"
            [placeholder]="Conversation?.stepStatus === 'TIMEOUT' ? 'Enter Timeout in sec' : 'Type your message here...'"
            rows="2"
          ></textarea>
          <button
            class="send-button"
            (click)="sendevent()"
            [disabled]="!message || message.trim() === ''"
          >
            <mat-icon>send</mat-icon>
          </button>
        </div>
      </div>
      <div class="metadata-input-section" *ngIf="activeInput === 'metadata'">
        <input
          type="text"
          class="custom-input"
          [(ngModel)]="newMetadata"
          placeholder="Type your message here..."
        />
        <div class="metadata-buttons">
          <button
            class="update-btn"
            (click)="updateStepData('reRun', 'metadata')"
            [disabled]="!newMetadata"
          >
            <mat-icon>replay</mat-icon>
            Update and Rerun
          </button>
          <button
            class="update-btn"
            (click)="updateStepData('aiAgent', 'metadata')"
            [disabled]="!newMetadata"
          >
            <mat-icon>add_circle</mat-icon>
            Add New Steps and Rerun
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
